<template>
  <div id="basic-container">
    <div id="room-background">
      <div id="logo-pos">
        <h1 class="playspace-logo">{{$t('global.app_name')}}</h1>
      </div> 
      <slot /> 
    </div>
  </div>
</template>

<script>
// this is used for logged out pages (login, register, welcome, etc...)
export default {
  name: "basiclayout"
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#basic-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  max-height: 768px;
  z-index: 150;
  margin: 0 auto;
}
</style>